import React from 'react'
import QImg1 from "../Assets/quest1.png";
import QImg2 from "../Assets/quest2.png";

function QuestionSection() {
  return (
    <div>
       <div className="test-section">

       
        <div className="test-series quest-p pL">
          <h3>Test Series</h3>
          <p>The questions provided in our app are meticulously curated and selected to enhance your knowledge, 
            featuring over 20,000+ questions across all subjects. We offer a variety of question sets categorized by 
            subject and topic, allowing you to focus on specific areas of study. Each question set is designed with different 
            difficulty levels to challenge and improve your understanding. Additionally, every set must be completed within a specified time limit, simulating the experience of an actual exam hall. This structure not only helps you manage your time effectively but also prepares you for the pressures of real exam conditions. With AgriQuiz, you’ll gain 
             and competence as you work through our extensive question bank, paving the way for your success.</p>
        </div>

         <div className="series-img  quest-img">
          <div className="img2">
            <img src={QImg1} alt="" />
          </div>
          <div className="img1 q-img">
            <img src={QImg2} alt="" />
          </div>
        </div>

      </div>
    </div>
  )
}

export default QuestionSection
