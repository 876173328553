import React from 'react'
import shubham from "../Assets/shubham2.jpg";
import akansha from "../Assets/akansha.png";
import khushi from "../Assets/khushi1.jpg";

function OurTeam() {
  return (
    <div>
      <div className="ourteam-section">
        <h3 className='pL'>Our Team</h3>
        <div className="members">
          <div className="member">
            <div className="mem-img">
              <img src={shubham} alt="" width={300} />
            </div>
            <div className="mem-content">
              <h4>Subham Muneshwar</h4>
              <h5>Founder-Agriculturist-Tech Enthusiast-Senior Developer</h5>
              <p>As an agriculture graduate and a tech enthusiast, I’ve experienced
                 firsthand the challenges students face when preparing for exams and job opportunities. 
                 The lack of focused platforms for effective practice is a common hurdle. That’s why I created 
                 AgriQuiz, a platform built to address this gap and offer a targeted solution for exam preparation.
              </p>
              <p>With over 20,000 MCQs covering critical agricultural topics, AgriQuiz allows students 
                to practice efficiently and build the knowledge needed for success. Additionally, we provide
                 video lectures to simplify complex topics and help clarify key concepts.
              </p>
              <p>At AgriQuiz, we’re committed to taking agriculture education to the next level, blending technology and learning to empower students on their path to success.</p>
            </div>
          </div>

          <div className="member">
            <div className="mem-content">
              <h4>Khushi Bahekar</h4>
              <h5>BCA SRU Raipur, Developer-Designer</h5>
              <p>As a developer with a background in BCA (Bachelor of Computer Applications), 
                I designed AgriQuiz to blend my technical expertise with the needs of agriculture students 
                preparing for competitive exams. Drawing on my own experience with the challenges of exam preparation, I aimed to create a user-friendly, effective platform. AgriQuiz offers a vast collection of MCQs covering all critical agricultural topics, alongside video lectures to help clarify difficult concepts. By integrating technology with education, AgriQuiz provides 
                students with the comprehensive tools they need to succeed in both academic and professional pursuits.</p>
            </div>
            <div className="mem-img">
              <img src={khushi} alt="" width={300} />
            </div>
            
           
          </div>
          <div className="member">
            <div className="mem-img">
              <img src={akansha} alt="" width={300} />
            </div>
            <div className="mem-content">
              <h4>Akanksha Swarnkar</h4>
              <h5>Dep. Microbiolog (IGKB) - social Meadia Manager</h5>
              <p>Agriculture is related to our  day to day life, learning agriculture related would be so easy if we would learn by scrolling .
                  How?
                  Now you'll be surprised to know about our Page AGRIQUIZ.
                  AGRIQUIZ helps you learning by scrolling in a very effective way , through images , videos and quizzes.
                  AGRIQUIZ enables you too learn visually .
                  Using infographics, videos ,and visual notes in our posts to help reinforce what you're learning.
                  We use various hashtags  like #agriculture to find relevant topics and find inspirationAGRIQUIZ helps
                  to that focus on your subject area, 
                  study tips, or motivational content. Many educators and students share helpful resources.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurTeam
