import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import logo from "../Assets/logo.PNG";
import menu from "../Assets/menu-bar.png";
function Header() {
  function myFunction() {
    var x = document.getElementById("myLinks");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }
  return (
    <div>
      <div className="header">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
            </Link>
        </div>
        <div className="menu">
            <ul id='myLinks' >
                <li><Link to="/about">About Us </Link></li>
                <li><Link to="#news">News</Link></li>
                <li><Link to="#blog">Blogs</Link></li>
                <li><Link to="#career">Career</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
            </ul>
        </div>
        <div className='menu-icon'>
        <a href="javascript:void(0);"  onClick={myFunction}><img src={menu} className="icon" alt="" /></a>
        </div>
      </div>
    </div>
  )
}

export default Header
// id="myLinks"