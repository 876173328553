import React from 'react'
import Header from "../Components/Header"
import HeroSection from '../Components/HeroSection'
import CountingSection from '../Components/CountingSection'
import TestSeries from '../Components/TestSeries'
import QuestionSection from '../Components/QuestionSection'
import LeaderBoardSection from '../Components/LeaderBoardSection'
import VideoSection from '../Components/VideoSection'
import Feedback from '../Components/Feedback'
import ContactUs from '../Components/ContactUs'
import Footer from '../Components/Footer'
function HomePage() {
  return (
    <div>
      <Header />
      <HeroSection />
      <CountingSection />
      <TestSeries />
      <QuestionSection />
      <LeaderBoardSection />
      <VideoSection />
      <Feedback />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default HomePage
