import React from 'react'
import question from "../Assets/question.jpeg"
import test from "../Assets/test-series.jpg"
import lecture from "../Assets/lecture.jpg"

function CountingSection() {
  return (
    <div>
      <div className="counting-section">
        <div className="boxs">
            <div className="box question-bg" >
              
                <h3>20,000+</h3>
                <p>Questions</p>
            </div>
            <div className="box test-bg">
                <h3>100+</h3>
                <p>Test Series </p>
            </div>
            <div className="box lecture-bg">
                <h3>10+</h3>
                <p>Lechures</p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default CountingSection
