import React from 'react'
import PlayStore from "../Assets/playstore.png";
import HeroImg from "../Assets/home_img1.png";
function HeroSection() {
  return (
    <div>
      <div className="hero-section">
        <div className="hero-content">
            <h4>Welcome to the</h4>
            <h1>AgriQuiz</h1>
            <p>Lets's prepare precisely</p>

            <div className="button">
              
              <div className="button-img">
                    <img src={PlayStore} alt="" />
                </div>
                <div className="button-name">
                    <h5>Get It</h5>
                    <h2>Google Play</h2>
                </div>
                <div className='coming'>Coming Soon</div>
            </div>
        </div>
        <div className="hero-img">
                <img src={HeroImg} alt="" />
        </div>
      </div>
    </div>
  )
}

export default HeroSection
