import React from 'react'
import Img1 from "../Assets/testSeries1.png";
import Img2 from "../Assets/testSeries2.png";
function TestSeries() {
  return (
    <div>
      <div className="test-section">
        <div className="series-img pL">
          <div className="img1">
            <img src={Img1} alt="" />
          </div>
          <div className="img2">
            <img src={Img2} alt="" />
          </div>
        </div>

        <div className="test-series pR">
          <h3>Test Series</h3>
          <p>AgriQuiz offers a comprehensive selection of test series designed to help you evaluate your learning 
            in a competitive and supportive environment. Our platform focuses on preparing students for various competitive
             exams in the agriculture field, catering to both higher education and job opportunities. With a diverse range of
              topics covered, students can effectively assess their knowledge and identify areas for improvement. In line with our
               vision for the future, we aim to introduce an on-demand test series feature, allowing students to request specific topics. Based on votes from the community, we will curate and provide tailored test series that meet your needs, 
            ensuring you have the best resources to excel in your agricultural studies and career pursuits.</p>
        </div>
      </div>
    </div>
  )
}

export default TestSeries
