import React from 'react'
import Profile1 from "../Assets/profile1.jpg";
import Profile2 from "../Assets/profile2.jpeg";

function LeaderBoardSection() {
  return (
    <div className='Leader-section'>
      <div className="leader-board">
        <div className="board">
          <div className="heading">
            <h2>LEADERBOARD</h2>
          </div>
          <div className="positions">
            <div className="position">
              <h4>2nd</h4>
              <div className="img">
                <img src={Profile2} alt="" />
              </div>
              <div className="winer-name">
                <p>Alis John</p>
              </div>
            </div>

            <div className="position first">
              <h4>1st</h4>
              <div className="img-1">
                <img src={Profile1} alt="" />
              </div>
              <div className="winer-name">
                <p>Alis John</p>
              </div>
            </div>

            <div className="position">
              <h4>3rd</h4>
              <div className="img">
                <img src={Profile2} alt="" />
              </div>
              <div className="winer-name">
                <p>Alis John</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeaderBoardSection
