import React from 'react'
import map from  "../Assets/map.png";

function ContactUs() {

  return (
    <div>
      <div className="contactus-section">
        <div className="map-section pL">
          <img src={map} alt="" />
        </div>
        <div className="contactus-form">
          <div className="form">
            <h2>Contact Us</h2>
            <div className='form-box'>
              <label htmlFor="">Name</label><br/>
              <input type="text" placeholder='Enter your name' name="name" id="" />
            </div>
            <div className='form-box'>
              <label htmlFor="">Email</label><br/>
              <input type="email" name="email" placeholder="Enter your email" id="" />
            </div>
            <div className='form-box'>
              <label htmlFor="">Message</label><br/>
              <textarea id="w3review" name="message" placeholder='Enter your message' rows="6" cols="30"></textarea>
            </div>
            <button className='submit-btn'>Submit</button>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
