import HomePage from './Pages/HomePage';
import './App.css';
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import AboutUs from './Pages/AboutUs';
import Contact from "./Pages/Contact";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />

        </Routes>
        
      </Router>
    </div>
  );
}

export default App;
