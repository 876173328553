import React from 'react'
import Profile from "../Assets/profile2.jpeg";

function Feedback() {
  return (
      <div className='feedback-section'>
          <h3 className='pL'>FeedBack</h3>

          <div className="feedback-boxs">
            <div className="box">
              <div className="img">
                <img src={Profile} alt="" />
              </div>
              <h4>John</h4>
              <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias temporibus vitae eius sed facilis </p>
              <p className='star'>&#9733;&#9733;&#9733;&#9733;&#9733;</p>
            </div>

            <div className="box">
              <div className="img">
                <img src={Profile} alt="" />
              </div>
              <h4>John</h4>
              <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias temporibus vitae eius sed facilis </p>
              <p className='star'>&#9733;&#9733;&#9733;&#9733;&#9733;</p>
            </div>

            <div className="box">
              <div className="img">
                <img src={Profile} alt="" />
              </div>
              <h4>John</h4>
              <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias temporibus vitae eius sed facilis </p>
              <p className='star'>&#9733;&#9733;&#9733;&#9733;&#9733;</p>
            </div>

              <div className="box">
              <div className="img">
                <img src={Profile} alt="" />
              </div>
              <h4>John</h4>
              <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias temporibus vitae eius sed facilis </p>
              <p className='star'>&#9733;&#9733;&#9733;&#9733;&#9733;</p>
              </div>
          </div>
      </div>
  )
}

export default Feedback
