import React from 'react'

function VideoSection() {
  return (
    <div>
      <div className="watch-section">
        <div className="video-section">
            <div className="video-content pL">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/69d7cfOodSE?si=4A5gKI2d7Us0mEzt" 
                frameborder="0"
                allow="accelerometer; autoplay;encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
                
            </div>
        </div>

        <div className="watch-video pL pR">
            <h3>Watch Videos</h3>
            <p>Utilize the video and text blogs section in our app to clarify your concepts.
               We produce curated videos focusing on challenging topics across various subjects, helping you grasp
                the concepts and the underlying logic more effectively. Our engaging content is designed to 
                simplify complex ideas and enhance your understanding. Additionally, you can scroll through our blogs 
                for deeper insights into different topics, allowing you to expand your knowledge further. With AgriQuiz, 
                you'll have the resources you need to master difficult concepts and excel in your studies.</p>

                 
        </div>
      </div>
    </div>
  )
}

export default VideoSection
