import React from "react";
import About from "../Assets/About.png";

function AboutHero() {
  return (
    <div>
      <div className="about-section">
        <div className="about-content">
          <h2>About Us</h2>
          <p className="pR">
            AgriQuiz is a mobile application dedicated to helping students
            prepare for exams in the field of agriculture. Our platform offers a
            vast collection of multiple-choice questions (MCQs) from a wide
            range of agricultural topics, providing comprehensive coverage for
            various competitive exams. Whether you're preparing for entrance
            exams, job-related assessments, or other agricultural
            certifications, AgriQuiz ensures that you have access to thousands
            of questions to test your knowledge. With test series designed
            specifically for different exams, we aim to make learning efficient
            and effective, empowering students to achieve success in their
            academic and professional pursuits.
          </p>
          <p className="pR">
            At AgriQuiz, our vision is to revolutionize agricultural learning by
            providing students with comprehensive tools for exam preparation. We
            aim to offer small video lectures as tutorials for enhanced
            learning, along with on-demand test series for a more personalized
            experience. By making knowledge accessible and interactive, we seek
            to drive academic excellence and foster innovation in the
            agricultural sector.
          </p>
        </div>
        <div className="about-img pL">
          <img src={About} alt="" />
        </div>
      </div>
    </div>
  );
}

export default AboutHero;
