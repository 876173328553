import React from 'react'
import ContactUs from '../Components/ContactUs'
import Header from '../Components/Header';
import Footer from '../Components/Footer';

function Contact() {
  return (
    <div>
      <Header />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default Contact
