import React from 'react'
import playstore from "../Assets/playstore.png";
import logo from "../Assets/logo.PNG";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function Footer() {
  return (
    <div >
      <div className="footer-section">
        <div className="logo-section pL">
          <div className="logo-img">
            <Link to="/"><img src={logo} alt="" /></Link>
          </div>
          <div className=" footer-btn button">
                <div className="button-name f-btn">
                    <p>Coming Soon</p>
                </div>
                <div className="button-img">
                    <img src={playstore} alt="" />
                </div>
            </div>
        </div>
      
      <div className="link">
      <div className="links">
        <ul>
          <li><a href="">Tutorial Videos</a></li>
          <li><a href="">Test Series</a></li>
          <li><a href="">Questions</a></li>
          <li><a href="">Blogs</a></li>
        </ul>
      </div>
      <div className="links">
        <ul>
          <li><Link to="/about">About Us</Link></li>
          <li><a href="">News</a></li>
          <li><a href="">Career</a></li>
          <li><Link to="/contact">Contact Us</Link></li>
        </ul>
      </div>
      <span>CopyRight	&#169;2024</span>
      </div>
      
      </div>

    </div>
  )
}

export default Footer
